import React, { FC, ReactNode } from 'react';
import useUniqueId from '../../../hooks/use-unique-id/use-unique-id';
import AnimationToggleScale from '../../animations/animation-toggle-scale/animation-toggle-scale';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';

interface Props {
  backgroundColor?: string;
  disabled?: boolean;
  error?: boolean;
  label?: ReactNode;
  labelSize?: 'sm' | 'base';
  onChange?: (value: boolean) => void;
  theme?: 'checkbox' | 'radio';
  value?: boolean;
}

const FieldCheckbox: FC<Props> = ({
  backgroundColor,
  disabled,
  error,
  label,
  labelSize,
  onChange,
  theme,
  value,
}: Props) => {
  const fieldId = useUniqueId();

  let background = backgroundColor;
  let pointerEvents = 'pointer-events-auto';
  let ring = 'ring-1 ring-inset ring-focus';
  let rounded = 'rounded-md';
  let iconColor = 'white';
  let iconBackground = 'bg-white';
  let labelWeight = 'font-normal';

  if (theme === 'radio') {
    rounded = 'rounded-full';
  }

  if (value) {
    background = 'bg-french-blue';
    labelWeight = 'font-semibold';
  }

  if (error) {
    background = 'bg-invalid-bg';
    ring = 'ring-inset ring-1 ring-invalid';
    iconColor = 'invalid';
    iconBackground = 'bg-invalid';
  }

  if (disabled) {
    background = 'bg-grey-50';
    pointerEvents = 'pointer-events-none';
    ring = '';
    iconColor = 'grey-400';
    iconBackground = 'bg-gray-400';
  }

  return (
    <div
      className={`
        flex items-center
        ${pointerEvents}
      `}
    >
      <input
        className="sr-visible hidden"
        type={theme}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value={value as any}
        onChange={() => onChange(theme === 'checkbox' ? !value : true)}
      />
      <button
        type="button"
        className={`
          w-7 h-7 focus:outline-none flex items-center justify-center flex-shrink-0
          ${rounded}
          ${ring}
          ${background}
        `}
        onClick={() => onChange(theme === 'checkbox' ? !value : true)}
        id={fieldId}
      >
        <AnimationToggleScale isActive={value}>
          <>
            {theme === 'checkbox' && (
              <IcomoonIcon name="check" size="base" color={iconColor} />
            )}
            {theme === 'radio' && (
              <div
                className={`
                  w-3 h-3 rounded-full
                  ${iconBackground}
                `}
              />
            )}
          </>
        </AnimationToggleScale>
      </button>
      {label && (
        <label
          className={`
          pl-4 py-1 cursor-pointer
          ${labelWeight}
          ${labelSize === 'sm' && 'text-sm'}
        `}
          htmlFor={fieldId}
        >
          {label}
        </label>
      )}
    </div>
  );
};

FieldCheckbox.defaultProps = {
  backgroundColor: 'bg-light-blue',
  disabled: false,
  error: false,
  label: null,
  labelSize: 'base',
  theme: 'checkbox',
  value: false,
  onChange: () => ({}),
};

export default FieldCheckbox;
