import { motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';

interface Props {
  readonly children: ReactNode;
  readonly className?: string;
  readonly initialHeight?: number;
  readonly isExpanded: boolean;
}

const AnimationExpand: FC<Props> = ({
  children,
  className,
  initialHeight,
  isExpanded,
}: Props) => {
  const variants = {
    isExpanded: { height: 'initial', opacity: 1 },
    isNotExpanded: { height: `${initialHeight}px`, opacity: 0.4 },
  };

  return (
    <motion.div
      animate={isExpanded ? 'isExpanded' : 'isNotExpanded'}
      className={`${className} overflow-hidden`}
      initial={isExpanded ? 'isExpanded' : 'isNotExpanded'}
      transition={{
        damping: 18,
        duration: 0.6,
        type: 'spring',
      }}
      variants={variants}
    >
      {children}
    </motion.div>
  );
};

AnimationExpand.defaultProps = {
  className: '',
  initialHeight: 0,
};

export default AnimationExpand;
