import { useRouter } from 'next/router';
import { useEffect, useState, useContext } from 'react';
import { GtmDataType } from '../../models/gtm-data-type';
import GtmEventNameEnum from '../../models/gtm-event-name-enum';
import useDidMountEffect from '../use-after-mount-effect/use-after-mount-effect';
import usePrevious from '../use-previous/use-pervious';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../components/providers/data-layer/data-layer.provider';

const usePushFormToDataLayer = (
  formTitle: string,
  fields: Record<string, unknown>,
  submitted: boolean,
  options: {
    errorMessage?: string;
    dataLayer?: GtmDataType;
    step?: number;
    section?: number;
  } = {}
): void => {
  const router = useRouter();
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const previousFields = usePrevious(fields);

  const [lastChangedField, setLastChangedField] = useState<string>();
  const [initialPath] = useState<string>(router.asPath);

  const push = (event: GtmEventNameEnum, dataLayer: GtmDataType = {}) =>
    pushToDataLayer(event, {
      formTitle,
      formSection: options.section || 1,
      formStep: options.step || 1,
      formHistory: lastChangedField,
      ...dataLayer,
      ...options.dataLayer,
    });

  useDidMountEffect(() => {
    if (!fields) {
      return;
    }

    // Find new fields, or fields where the value is different
    const changedFields: string[] = Object.keys(fields).filter(
      (property) =>
        (previousFields && !Object.keys(previousFields).includes(property)) ||
        (previousFields && fields[property] !== previousFields[property])
    );

    if (changedFields.length > 0) {
      setLastChangedField(changedFields[0]);
    }
  }, [fields]);

  useDidMountEffect(() => {
    if (lastChangedField) {
      push(GtmEventNameEnum.FormProgress);
    }
  }, [lastChangedField, options.step, options.section]);

  useDidMountEffect(() => {
    const { errorMessage } = options;

    if (errorMessage?.length) {
      push(GtmEventNameEnum.FormError, { errorMessage });
    }
  }, [options.errorMessage]);

  useEffect(() => {
    if (!submitted && router.asPath !== initialPath) {
      push(GtmEventNameEnum.FormAbandon);
    }
  }, [lastChangedField, router.asPath, submitted]);

  useEffect(() => {
    const handler = () => {
      if (!submitted) {
        push(GtmEventNameEnum.FormAbandon);
      }
    };

    window.addEventListener('beforeunload', handler);

    return () => window.removeEventListener('beforeunload', handler);
  }, [lastChangedField, submitted]);

  useEffect(() => {
    if (submitted) {
      push(GtmEventNameEnum.FormSuccess);
    }
  }, [submitted]);
};

export default usePushFormToDataLayer;
