import React, { useContext } from 'react';
import calculateLineItemQuantity from '../../../../helpers/calculate-line-item-quantity';
import interpolateContent from '../../../../helpers/interpolate-content';
import { BasketAddProductErrorLabelsType } from '../../../../models/basket-add-product-error-labels-type';
import { BasketClearQuoteLabelsType } from '../../../../models/basket-clear-quote-labels-type';
import BasketLineItemIssueEnum from '../../../../models/basket-line-item-issue-enum';
import { BasketType } from '../../../../models/basket-type';
import { ProductBasketLineItemType } from '../../../../models/product-basket-line-item-type';
import guestAddLineItems from '../../../../services/content-api/endpoints/basket/guest/add-line-items';
import memberAddLineItems from '../../../../services/content-api/endpoints/basket/member/add-line-items';
import categoryStructure from '../../../../services/content-api/endpoints/category/structure';
import AddToBasketErrorsModal from '../../../molecules/add-to-basket-errors-modal/add-to-basket-errors-modal';
import open from '../../overlay/open.overlay.actions';
import { OverlayDispatchContext } from '../../overlay/overlay.provider';
import useGetSessionId from '../../user/hooks/use-get-session-id.hook';
import { UserStateContext } from '../../user/user.provider';
import basketLoading from '../actions/basket-loading';
import updateBasket from '../actions/update-basket';
import { BasketDispatchContext, BasketStateContext } from '../basket.provider';
import useAddConfirmedBackOrderSku from './use-add-confirmed-back-order-sku';
import useClearBasket from './use-clear-basket';
import setLastAddedVariantSkus from '../actions/set-last-added-variant-sku';

const useAddMultipleToBasket: (
  addProductErrorLabels: BasketAddProductErrorLabelsType,
  clearQuoteLabels: BasketClearQuoteLabelsType,
  options?: { ignoreClearQuoteAlert: boolean }
) => (
  items: {
    variantSku: string;
    quantity: number;
  }[]
) => Promise<BasketType> = (
  addProductErrorLabels,
  clearQuoteLabels,
  { ignoreClearQuoteAlert } = { ignoreClearQuoteAlert: false }
) => {
  const { auth } = useContext(UserStateContext);
  const overlayDispatch = useContext(OverlayDispatchContext);
  const basketDispatch = useContext(BasketDispatchContext);
  const basketState = useContext(BasketStateContext);
  const clearBasket = useClearBasket(clearQuoteLabels);
  const getSessionId = useGetSessionId();
  const addConfirmedBackOrderSku = useAddConfirmedBackOrderSku();

  return async (items) => {
    basketDispatch(basketLoading(true));

    let { basket } = basketState;
    const { catalogId } = await categoryStructure();

    try {
      if (basketState.quote && !ignoreClearQuoteAlert) {
        await clearBasket();
      }
    } catch {
      basketDispatch(basketLoading(false));

      return basket;
    }

    try {
      basket = auth.accessToken
        ? await memberAddLineItems(
            catalogId,
            await getSessionId(),
            basketState.id,
            items
          )
        : await guestAddLineItems(
            catalogId,
            await getSessionId(),
            basketState.id,
            items
          );
    } catch (error) {
      basketDispatch(basketLoading(false));

      throw error;
    }

    basketDispatch(updateBasket(basket));
    basketDispatch(basketLoading(false));

    const itemsWithIssues: string[] = [];

    items
      .map((item) => item.variantSku)
      .forEach((sku) => {
        if (
          basket.lineItemIssues?.length &&
          basket.lineItemIssues
            ?.map((issue) => issue.variantSku.toUpperCase())
            .includes(sku.toUpperCase())
        ) {
          itemsWithIssues.push(sku);
        }
      });

    if (itemsWithIssues?.length) {
      const foundItemIssue = basket.lineItemIssues?.find((issue) =>
        itemsWithIssues.includes(issue.variantSku)
      );

      const issueDescription =
        foundItemIssue?.errorMessage ===
        BasketLineItemIssueEnum.InsufficientClearanceStock
          ? addProductErrorLabels.insufficientClearanceStockError ||
            'Insufficient Clearance Stock'
          : interpolateContent(
              addProductErrorLabels.addProductErrorDescription,
              foundItemIssue?.errorMessage || 'Error'
            );

      overlayDispatch(
        open(
          <AddToBasketErrorsModal
            heading={addProductErrorLabels.addProductErrorHeading}
            description={issueDescription}
            body={addProductErrorLabels.addProductErrorBody}
            skus={itemsWithIssues}
          />
        )
      );
    }

    const addedSkuVariants = [];
    items.forEach(({ variantSku }) => {
      const foundBasketItem = basket.lineItems.find(
        (basketItem: ProductBasketLineItemType) =>
          basketItem.variantSku.toUpperCase() === variantSku.toUpperCase()
      );

      if (
        foundBasketItem &&
        calculateLineItemQuantity(foundBasketItem)?.backOrder
      ) {
        addConfirmedBackOrderSku(foundBasketItem.variantSku);
      }

      if (foundBasketItem) {
        addedSkuVariants.push(foundBasketItem.variantSku);
      }
    });

    if (addedSkuVariants.length) {
      basketDispatch(setLastAddedVariantSkus(addedSkuVariants));
      setTimeout(() => {
        basketDispatch(setLastAddedVariantSkus([]));
      }, 5000);
    }

    basketDispatch(basketLoading(false));

    return basket;
  };
};

export default useAddMultipleToBasket;
