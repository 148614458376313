import React, { FC } from 'react';

interface Props {
  disabled?: boolean;
  error?: boolean;
  options?: {
    value: string | number | null;
    label: string;
  }[];
  onChange?: (value: string | number | null) => void;
  value?: string | number | null;
  className: string;
}

const TabSelect: FC<Props> = ({
  disabled,
  error,
  options,
  value,
  className = '',
  onChange,
}: Props) => {
  let background = 'border border-medium-blue  ';
  const activeBackground = 'font-bold border border-french-blue bg-light-blue';

  if (disabled) {
    background = 'border border-grey-100 bg-grey-300';
  }

  if (error) {
    background = 'bg-invalid-bg';
  }
  const isActive = (activeVal, buttonVal) => {
    return activeVal === buttonVal;
  };

  return (
    <div className="flex flex-row gap-1">
      {options?.map((item) => (
        <button
          type="button"
          disabled={disabled}
          className={`px-2 py-4 rounded-md ${
            isActive(item.value, value) ? activeBackground : background
          } ${className}`}
          key={item.label}
          onClick={(event) => {
            onChange(item.value);
            event.preventDefault();
          }}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

TabSelect.defaultProps = {
  options: [],
  value: null,
  onChange: () => ({}),
  disabled: false,
  error: false,
};

export default TabSelect;
