import { useField } from 'formik';
import React, { FC, ReactNode } from 'react';
import FieldControl from '../field-control/field-control';
import FieldText from '../field-text/field-text';

interface Props {
  alwaysShowRing?: boolean;
  backgroundColor?: string;
  ringColor?: string;
  placeholderColor?: string;
  disabled?: boolean;
  help?: string;
  iconName?: string;
  iconPosition?: 'left' | 'right';
  label?: string;
  labelColor?: string;
  max?: number;
  min?: number;
  name: string;
  onBlur?: () => void;
  onChange?: (value: string | number) => void;
  onIconClick?: () => void;
  paddingX?: 'full' | 'half';
  placeholder?: string;
  required?: boolean;
  step?: number;
  textAlign?: 'left' | 'center';
  tooltipComponent?: ReactNode;
  tooltipSize?: 'small' | 'large';
  type?: string;
  inputClassName?: string;
  containerClassName?: string;
  inputProps?: object;
  className?: string;
}

const FormText: FC<Props> = ({
  alwaysShowRing,
  backgroundColor,
  ringColor,
  placeholderColor,
  disabled,
  help,
  iconName,
  iconPosition,
  label,
  labelColor,
  max,
  min,
  name,
  onBlur,
  onChange,
  onIconClick,
  paddingX,
  placeholder,
  required,
  step,
  textAlign,
  tooltipComponent,
  tooltipSize,
  type,
  inputClassName,
  containerClassName,
  inputProps,
  className
}: Props) => {
  const [field, meta, helpers] = useField(name);

  return (
    <FieldControl
      error={meta.touched && !!meta.error ? meta.error : null}
      help={help}
      label={label}
      labelColor={disabled ? 'grey-600' : labelColor}
      required={required}
      tooltipComponent={tooltipComponent}
      tooltipSize={tooltipSize}
      containerClassName={containerClassName}
    >
      <FieldText
        alwaysShowRing={alwaysShowRing}
        backgroundColor={backgroundColor}
        ringColor={ringColor}
        placeholderColor={placeholderColor}
        disabled={disabled}
        error={meta.touched && !!meta.error}
        iconName={iconName}
        iconPosition={iconPosition}
        max={max}
        min={min}
        onBlur={onBlur}
        onChange={(value) => {
          helpers.setValue(value);
          onChange(value);
        }}
        onIconClick={() => onIconClick()}
        paddingX={paddingX}
        placeholder={placeholder}
        step={step}
        textAlign={textAlign}
        type={type}
        value={field.value}
        inputClassName={inputClassName}
        inputProps={inputProps}
      />
    </FieldControl>
  );
};

FormText.defaultProps = {
  alwaysShowRing: false,
  backgroundColor: 'light-blue',
  ringColor: 'focus',
  placeholderColor: 'grey-600',
  disabled: false,
  help: null,
  iconName: null,
  iconPosition: 'left',
  label: null,
  labelColor: 'black',
  max: null,
  min: null,
  onBlur: null,
  onChange: () => ({}),
  onIconClick: () => ({}),
  paddingX: 'full',
  placeholder: null,
  required: false,
  step: null,
  textAlign: 'left',
  tooltipComponent: null,
  tooltipSize: 'small',
  type: 'text',
  inputClassName: '',
  containerClassName: '',
  inputProps: {},
};

export default FormText;
