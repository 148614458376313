import { useField } from 'formik';
import React, { FC, ReactNode } from 'react';
import FieldControl from '../field-control/field-control';
import FieldSelect from '../field-select/field-select';
import TabSelect from '../tab-select/tab-select';

interface Props {
  disabled?: boolean;
  help?: string;
  label?: string;
  name: string;
  onChange?: (value: string | number | null) => void;
  options?: {
    value: string | number | null;
    label: string;
  }[];
  placeholder?: string;
  required?: boolean;
  tooltipComponent?: ReactNode;
  tooltipSize?: 'small' | 'large';
}

const FormSelect: FC<Props> = ({
  disabled,
  help,
  label,
  name,
  onChange,
  options,
  placeholder,
  required,
  tooltipComponent,
  tooltipSize,
}: Props) => {
  const [field, meta, helpers] = useField(name);

  return (
    <FieldControl
      error={meta.touched && !!meta.error ? meta.error : null}
      help={help}
      label={label}
      labelColor={disabled ? 'grey-600' : null}
      required={required}
      tooltipComponent={tooltipComponent}
      tooltipSize={tooltipSize}
      classNames={name === 'countryCode' ? 'm-auto w-auto' : ''}
    >
      {options.length >= 2 ? (
        <FieldSelect
          disabled={disabled}
          error={meta.touched && !!meta.error}
          onChange={(value) => {
            helpers.setValue(value);
            onChange(value);
          }}
          options={options}
          placeholder={placeholder}
          value={field.value}
        />
      ) : (
        <TabSelect
          disabled={disabled}
          error={meta.touched && !!meta.error}
          onChange={(value) => {
            helpers.setValue(value);
            onChange(value);
          }}
          options={options}
          value={field.value}
          className={name === 'countryCode' ? 'w-24' : ''}
        />
      )}
    </FieldControl>
  );
};

FormSelect.defaultProps = {
  disabled: false,
  help: null,
  label: null,
  onChange: () => ({}),
  options: [],
  placeholder: null,
  required: false,
  tooltipComponent: null,
  tooltipSize: 'small',
};

export default FormSelect;
