const scrollToElement = ({
  ref,
  offsetHeight = 0,
  behavior = 'smooth',
}: {
  ref: string | HTMLElement;
  offsetHeight?: number;
  behavior?: ScrollBehavior;
}): void => {
  if (!ref) return;

  const navHeight =
    document.querySelector('#main-nav')?.getBoundingClientRect().height || 0;
  const element = typeof ref === 'string' ? document.getElementById(ref) : ref;

  window.scrollTo({
    top:
      window.scrollY +
      element.getBoundingClientRect().top -
      navHeight -
      offsetHeight,
    behavior,
  });
};

export default scrollToElement;
