import client from '../../../client';
import CultureCodeEnum from '../../../../../models/culture-code-enum';
import { BasketType } from '../../../../../models/basket-type';

export default async function addLineItems(
  catalogId: string,
  session: string,
  basketId: string,
  items: { variantSku: string; quantity: number }[]
): Promise<BasketType> {
  const { data } = await client.post<BasketType>(
    '/basket/member/add-line-items',
    {
      cultureCode: CultureCodeEnum.EnGb,
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      provider: 0,
      catalogId,
      session,
      basketId,
      isCustomerCode: true,
      items: items.map((item) => ({
        ...item,
        addToExistingLine: true,
      })),
    }
  );

  return data;
}
