import React, { FC, useEffect, useRef, useState } from 'react';
import Ripples from 'react-ripples';
import { motion } from 'framer-motion';
import IcomoonIconButton from '../../atoms/icomoon-icon-button/icomoon-icon-button';
import useIsBreakpoint from '../../../hooks/use-is-breakpoint/use-is-breakpoint';
import BreakpointEnum from '../../../models/breakpoint-enum';
import getTailwindConfig from '../../../helpers/get-tailwind-config';

interface Props {
  readonly className?: string;
  readonly onPageChange?: (page: number, itemsPerPage: number) => void;
  readonly itemsPerPage: number;
  readonly totalItems: number;
}

const ItemPagination: FC<Props> = ({
  className,
  onPageChange,
  itemsPerPage,
  totalItems,
}: Props) => {
  const [buttonPosition, setButtonPosition] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  const pageContainerRef = useRef(null);

  const breakpoint = useIsBreakpoint();
  const buttonsVisible = breakpoint === BreakpointEnum.sm ? 2 : 3;
  const buttonWidth = breakpoint === BreakpointEnum.sm ? 48 : 72;

  const noOfPages = Math.ceil(totalItems / itemsPerPage);
  const isPrevDisabled = page === 0;
  const isNextDisabled = page + 1 === noOfPages;

  const fullConfig = getTailwindConfig();
  const frenchBlue = fullConfig.theme.colors['french-blue'];
  const { black } = fullConfig.theme.colors;

  const handlePageChange = (selectedPage: number): void => {
    setPage(selectedPage);
    onPageChange(selectedPage, itemsPerPage);
  };

  useEffect(() => {
    const pageContainerWidth =
      pageContainerRef.current.getBoundingClientRect().width;
    setButtonPosition(
      pageContainerWidth / 2 - ((page + 1) * buttonWidth - buttonWidth / 2)
    );
  });

  useEffect(() => {
    if (page === 0) {
      return;
    }

    handlePageChange(0);
  }, [itemsPerPage, totalItems]);

  return (
    <div
      className={`${className} flex items-center justify-between mx-auto lg:w-10/12 xl:w-8/12`}
    >
      <div
        className={
          `${isPrevDisabled ? 'border-grey-200' : 'border-french-blue'} ` +
          'border-3 rounded-full'
        }
      >
        <IcomoonIconButton
          className="m-2"
          color={isPrevDisabled ? 'grey-200' : 'black'}
          disabled={isPrevDisabled}
          name="chevron-left"
          onClick={() => handlePageChange(page - 1)}
          size="lg"
        />
      </div>

      <div
        className="h-10 overflow-x-hidden relative w-full"
        ref={pageContainerRef}
      >
        <motion.div
          animate={{ left: `${buttonPosition}px`, opacity: 1 }}
          className="absolute inset-y-0 w-auto"
          initial={{ left: `${buttonPosition}px`, opacity: 0 }}
          transition={{ duration: 0.1 }}
        >
          <div className="flex">
            {Array(noOfPages)
              .fill(null)
              .map((item: null, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="text-center w-12 md:w-18">
                  <Ripples
                    className="rounded-full"
                    color={`rgba(${frenchBlue.rgb}, .4)`}
                    during={1200}
                  >
                    <motion.button
                      animate={{
                        color:
                          page === index ? frenchBlue.DEFAULT : black.DEFAULT,
                        opacity:
                          Math.abs(page - index) / buttonsVisible >= 1 ? 0 : 1,
                      }}
                      initial={{
                        color:
                          page === index ? frenchBlue.DEFAULT : black.DEFAULT,
                        opacity:
                          Math.abs(page - index) / buttonsVisible >= 1 ? 0 : 1,
                      }}
                      className={
                        `${page === index ? 'font-bold ' : ''}` +
                        'focus:outline-none p-2 mx-2 rounded-full'
                      }
                      onClick={() => handlePageChange(index)}
                      type="button"
                    >
                      {index + 1}
                    </motion.button>
                  </Ripples>
                </div>
              ))}
          </div>
        </motion.div>
      </div>

      <div
        className={
          `${isNextDisabled ? 'border-grey-200' : 'border-french-blue'} ` +
          'border-3 rounded-full'
        }
      >
        <IcomoonIconButton
          className="m-2"
          color={isNextDisabled ? 'grey-200' : 'black'}
          disabled={isNextDisabled}
          name="chevron-right"
          onClick={() => handlePageChange(page + 1)}
          size="lg"
        />
      </div>
    </div>
  );
};

ItemPagination.defaultProps = {
  className: '',
  onPageChange: () => ({}),
};

export default ItemPagination;
