import React, {
  FC,
  FocusEventHandler,
  MouseEventHandler,
  ReactNode,
} from 'react';

interface Props {
  readonly className?: string;
  readonly children: ReactNode;
  readonly onBlur?: FocusEventHandler;
  readonly onClick?: MouseEventHandler;
  readonly onFocus?: FocusEventHandler;
  readonly onMouseLeave?: MouseEventHandler;
  readonly onMouseOver?: MouseEventHandler;
}

const TableRow: FC<Props> = ({
  className,
  children,
  onBlur,
  onClick,
  onFocus,
  onMouseLeave,
  onMouseOver,
}: Props) => (
  <tr
    className={`${className} flex lg:table-row flex-wrap pt-1 md:pt-2 lg:p-0 rounded-lg lg:rounded-none`}
    onBlur={onBlur}
    onClick={onClick}
    onFocus={onFocus}
    onMouseLeave={onMouseLeave}
    onMouseOver={onMouseOver}
  >
    {children}
  </tr>
);

TableRow.defaultProps = {
  className: '',
  onBlur: null,
  onClick: null,
  onFocus: null,
  onMouseLeave: null,
  onMouseOver: null,
};

export default TableRow;
