import { useCallback } from 'react';

let id = 1;

export default function useUniqueId(): string {
  return useCallback(() => {
    id = Number(id) + 1;

    return `use-unique-id-${id}`;
  }, [])();
}
