import { useState } from 'react';

export default function useAccordion<T>(
  allowMultiSelect: boolean,
  initiallySelected: T[] = []
): {
  setSelectedItems: (items: T[]) => void;
  toggleSelectedItem: (item: T) => void;
  selectedItems: T[];
} {
  const [selectedItems, setSelectedItems] = useState<T[]>(initiallySelected);

  const toggleSelectedItem = (item: T) => {
    if (!allowMultiSelect) {
      if (selectedItems.includes(item)) {
        setSelectedItems([]);

        return;
      }

      setSelectedItems([item]);

      return;
    }

    const index = selectedItems.indexOf(item);
    const selected = [...selectedItems];

    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(item);
    }

    setSelectedItems(selected);
  };

  return {
    setSelectedItems,
    toggleSelectedItem,
    selectedItems,
  };
}
