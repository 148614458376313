import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useRef, useState } from 'react';
import useIsTouchDevice from '../../../hooks/use-is-touch-device/use-is-touch-device';
import useOutsideClick from '../../../hooks/use-outside-click/use-outside-click';
import AnimationToggleRotate from '../../animations/animation-toggle-rotate/animation-toggle-rotate';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';

interface Props {
  disabled?: boolean;
  placeholder?: string;
  error?: boolean;
  options?: {
    value: string | number | null;
    label: string;
  }[];
  onChange?: (value: string | number | null) => void;
  value?: string | number | null;
  className?: string;
}

const FieldSelect: FC<Props> = ({
  disabled,
  error,
  options,
  value,
  placeholder,
  onChange,
  className,
}: Props) => {
  const isTouchDevice = useIsTouchDevice();
  const containerComponent = useRef<HTMLDivElement>();
  const selectComponent = useRef<HTMLSelectElement>();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const selectedText = options.find((option) => option.value === value)?.label;

  let background = 'bg-light-blue';
  let iconColor = 'french-blue';
  let ring = 'ring-inset focus:ring-1';
  let rounded = 'rounded-md';
  let selectPointerEvents = 'pointer-events-none';
  let selectedValueTextColor = 'text-black';

  if (disabled) {
    background = 'bg-grey-50';
    iconColor = 'grey-600';
    ring = '';
    selectedValueTextColor = 'text-grey-600';
  }

  if (isFocused) {
    ring = 'ring-inset ring-focus ring-1';
    rounded = 'rounded-t-md';
  }

  if (error) {
    background = 'bg-invalid-bg';
    iconColor = 'invalid';
    ring = 'ring-inset ring-invalid ring-1';
  }

  if (!value) {
    selectedValueTextColor = 'text-grey-600';
  }

  if (isTouchDevice && !disabled) {
    selectPointerEvents = 'pointer-events-auto';
  }

  useOutsideClick(containerComponent, () => setIsFocused(false));

  return (
    <div ref={containerComponent} className={`relative ${className}`}>
      <button
        type="button"
        className={`
          w-full text-left flex items-center px-6 py-4 focus:outline-none
          ${ring}
          ${background}
          ${rounded}
          ${disabled ? 'cursor-default' : ''}
        `}
        onClick={() => !disabled && setIsFocused(!isFocused)}
      >
        <span
          className={`inline-block flex-grow truncate ${selectedValueTextColor}`}
        >
          {selectedText || placeholder}
        </span>

        <span className="flex-shrink-0 ml-4">
          <AnimationToggleRotate isRotated={isFocused}>
            <IcomoonIcon name="chevron-down" color={iconColor} size="base" />
          </AnimationToggleRotate>
        </span>
      </button>

      <AnimatePresence>
        {isFocused && (
          <motion.div
            animate={{ opacity: 1, y: 0 }}
            className="absolute top-full left-0 right-0 bg-white shadow-md p-1 rounded-b-md flex flex-col max-h-64 overflow-y-auto overflow-x-hidden z-30"
            exit={{ opacity: 0, y: 0 }}
            initial={{ opacity: 0.4, y: -20 }}
          >
            {options.map((option) => (
              <button
                key={String(option.value)}
                type="button"
                className="px-6 py-3 rounded-md text-left hover:bg-french-blue hover:text-white"
                onClick={(event) => {
                  onChange(option.value);
                  setIsFocused(false);
                  event.preventDefault();
                }}
              >
                {option.label}
              </button>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <select
        ref={selectComponent}
        tabIndex={-1}
        className={`absolute opacity-0 inset-0 w-full cursor-pointer ${selectPointerEvents}`}
        disabled={disabled}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        {!value && (
          <option selected value={null}>
            {placeholder}
          </option>
        )}

        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

FieldSelect.defaultProps = {
  options: [],
  value: null,
  onChange: () => ({}),
  placeholder: null,
  disabled: false,
  error: false,
  className: '',
};

export default FieldSelect;
