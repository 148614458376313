import { FormikProps, FormikValues } from 'formik';
import { MutableRefObject, useRef } from 'react';
import usePushFormToDataLayer from '../use-push-form-to-data-layer/use-push-form-to-data-layer';

const usePushFormikFormToDataLayer = <Values extends FormikValues>(
  formTitle: string,
  submitted: boolean,
  transform: (values: Values) => Values = (values) => values
): MutableRefObject<FormikProps<Values>> => {
  const formContext = useRef<FormikProps<Values>>();

  const errorMessage: string | undefined = Object.keys(
    formContext.current?.errors || {}
  ).reduce((error, key) => {
    return !error && formContext.current.getFieldMeta(key).touched
      ? formContext.current.getFieldMeta(key).error
      : error;
  }, undefined);

  usePushFormToDataLayer(
    formTitle,
    transform?.(formContext.current?.values) || formContext.current?.values,
    submitted,
    { errorMessage }
  );

  return formContext;
};

export default usePushFormikFormToDataLayer;
